.absolute{
    &-top-left{
        position: absolute;
        top: 0;
        left: 0;
    }
    &-top-right{
        position: absolute;
        top: 0;
        right: 0;
    }
    &-bottom-left{
        position: absolute;
        bottom: 0;
        left: 0;
    }
    &-bottom-right{
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
.pos-rel {
    @extend .position-relative;
}
.pos-abs {
    @extend .position-absolute;
}