@import 'toasts-animations';

// Appearance animation
@keyframes swal2-show {
  0% {
    opacity: 0 !important;
    transform: translate(0, 20px) !important;
  }
  40% {
    opacity: 1 !important;
  }
  100% {
    transform: translate(0, 0) !important;
  }
}

// Disppearance animation
@keyframes swal2-hide {
  0% {
    transform: scale(1) !important;
    opacity: 1 !important;
  }

  100% {
    transform: scale(.7) !important;
    opacity: 0 !important;
  }
}

// Success icon animations
@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0;
  }

  54% {
    top: 1.0625em;
    left: .125em;
    width: 0;
  }

  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em;
  }

  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }

  100% {
    top: 2.8125em;
    left: .875em;
    width: 1.5625em;
  }
}

@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }

  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }

  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }

  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em;
  }
}

@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

// Error icon animations
@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(.4);
    opacity: 0;
  }

  50% {
    margin-top: 1.625em;
    transform: scale(.4);
    opacity: 0;
  }

  80% {
    margin-top: -.375em;
    transform: scale(1.15);
  }

  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes swal2-animate-error-icon {
  0% {
    transform: scale(.8) rotateX(100deg);
    opacity: 0;
  }

  100% {
    transform: scale(.8) rotateX(0deg);
    opacity: 1;
  }
}

@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
