//////////////////////////////////
//  IV WG 5
//////////////////////////////////
.nk-iv-wg5{
	display: inline-flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	&-head{
		margin-bottom: 1rem;
	}
	&-title{
		margin-bottom: .25rem;
	}
	&-subtitle{
		color: $base-light;
	}
	&-ck{
		margin-top: auto;
		position: relative;
		display: inline-block;
		&-result{
			position: absolute;
			top: 55%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			.text-lead{
				font-size: 40px;
				font-weight: 300;
				color: $base-color;
				line-height: 1.3;
				&.sm{
					font-size: 30px;
				}
			}
			.text-sub{
				font-size: 12px;
				font-weight: 500;
				color: $base-light;
			}
		}
		&-minmax{
			display: flex;
			justify-content: space-between;
			color: $base-light;
			font-size: 12px;
		}
	}
}

@include media-breakpoint-up(xl){
	.nk-iv-wg5{
		&-ck{
			canvas{
				width: 300px !important;
				height: 150px !important;
			}
			&.sm{
				canvas{
					width: 240px !important;
					height: 120px !important;
				}
			}
		}
	}
}