//#v302 - @ih
.col{
    &-sep{
        > * + *{
            border-top: 1px solid $border-light;
        }
        &-vr{
            > * + *{
                border-left: 1px solid $border-light;
            }
        }
    }
}
@each $name, $value in $container-max-widths {
    @include media-breakpoint-up($name){
        .col{
            &-sep{
                &-#{$name}{
                    > * + *{
                        border-top: none;
                        border-left: 1px solid $border-light;
                    }
                    &-vr{
                        > * + *{
                            border-left: none;
                            border-top: 1px solid $border-light;
                        }
                    }
                }
            }
        }
    }
}
