// Included Global Variables and Functions
@import "dashlite_variables";

// Included Fonts
/** 01. FONTS */
@import "core/fonts/roboto";
@import "core/fonts/nunito";

// Included Bootstarp
@import "extend/bootstrap/variables";
@import "extend/bootstrap/bootstrap";
@import "extend/bootstrap/extend";

// Include Other Vendors
@import "vendors/bundle";

// Include Core Dashlite Style
@import "core/style";

////////////////////////// DO NOT REMOVE ABOVE ///////////////////////

////// GLOBAL INCLUDED
@import "global/style";

//////  APPLICATONS - Common Uses
@import "apps/asterisk";
@import "apps/reply-comment";
@import "apps/attach";

//////  Apps
@import "apps/messages";  		// - Support Messages
@import "apps/inbox";  			// - Inbox/ Mail App
@import "apps/file-manager";  	// - File Manager
@import "apps/chats";  			// - Chats

///////  PREVIEW PURPOSE
@import "core/preview"; 		// Can be remove on real projoect

///////  UI/MODE OVERRIDE
@import "core/mode-override";

///////  DARK MODE SKIN
@import "core/dark-skin";

/*! END @iO */
////////////////////////// END STYLESHEET ////////////////////////////

.terminal {
    font-size: medium;
    background-color: black;
    color: green;
    padding: 14px;
    overflow-y: auto;
    height: 80vh;
    width: 100%;
    text-align: start;
}

.log {
    font-family: revert-layer;
    white-space: pre-wrap;
    font-size: larger;
}
.warning {
    font-family: revert-layer;
    white-space: pre-wrap;
    font-size: larger;
    color: #ffa300;
}
.error {
    font-family: revert-layer;
    white-space: pre-wrap;
    font-size: larger;
    color: #ff0000;
}
.debug {
    font-family: revert-layer;
    white-space: pre-wrap;
    font-size: larger;
    color: $pink;
}
.react-datepicker-popper{
    z-index: 9999;
}

/* Add this CSS to your stylesheets */
.scrollable-menu {
    max-height: 200px; /* Set your desired max height for the dropdown menu */
    overflow-y: auto; /* Add a vertical scrollbar if the content overflows */
    scrollbar-width: thin; /* Specify a thin scrollbar on Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1); /* Specify scrollbar track and thumb colors on Firefox */

    /* For Webkit browsers like Chrome and Safari */
    &::-webkit-scrollbar {
        width: 4px; /* Set the width of the scrollbar */
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5); /* Set the color of the scrollbar thumb */
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.1); /* Set the color of the scrollbar track */
    }
}

* {

    scrollbar-width: thin;
    scrollbar-color:  $base-light;


}

div[class*="-MenuPortal"] {
    z-index: 9999 !important;
}

[class*="dropdown-indicator"]::after {
    border: none !important;
    font-family: "Nioicon";
    vertical-align: middle;
    content: " ";
    margin-left: 0.25rem;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
}

.form-control-select::after {
    font-family: "Nioicon";
    content: " ";
    pointer-events: none;
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    right: 1px;
    top: 1px;
    width: calc(calc(2.125rem + 2px) - 2px);
    height: calc(calc(2.125rem + 2px) - 2px);
}