///////////////////////////////////
/// Table Compact - minimal
///////////////////////////////////
.is-compact {
    .tb-tnx, .tb-odr {
        &-item td {
            padding-top: .4rem;
            padding-bottom: .4rem;
        }
    }
}
@media (max-width: 767px){
    .is-compact {
        .tb-tnx, .tb-odr {
            &-item {
                padding-top: .5rem;
                padding-bottom: .5rem;
                td {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
}